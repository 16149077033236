import React from 'react'

import SEO from '../components/seo'

const NotFoundPage = () => (
   <React.Fragment>
      <SEO title="404: Not found" />
      <h1 style={{ color: '#49DDC7' }}>NOT FOUND</h1>
      <p style={{ color: '#49DDC7' }}>
         You just hit a route that doesn&#39;t exist... the sadness.
      </p>
   </React.Fragment>
)

export default NotFoundPage
